import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

const Archive = () => (
  <StaticQuery
    query={graphql`
      query BlogPostArchive {
        allMarkdownRemark(
          limit: 5
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              frontmatter {
                title
                slug
                date(formatString: "DD. MMMM YYYY", locale: "de")
              }
            }
          }
        }
      }
    `}
    render={({ allMarkdownRemark }) => (
      <>
        <aside>
          <hr className="archive-hr" />
          <h3>Kürzlich erschienene Artikel</h3>
          <ul className="archive-list">
            {allMarkdownRemark.edges.map(edge => (
              <li key={edge.node.frontmatter.slug}>
                <Link to={`/posts/${edge.node.frontmatter.slug}`}>
                  {edge.node.frontmatter.title}
                </Link>
                , {edge.node.frontmatter.date}
              </li>
            ))}
          </ul>
        </aside>
      </>
    )}
  />
);

export default Archive;
