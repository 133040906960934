import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import siteLogo from "../images/site-logo.svg";

const Header = ({ siteTitle }) => (
  <header>
    <div className="header-div">
      <div className="title-div">
        <Link to="/">
          <img className="logo" src={siteLogo} alt="Logo" />{" "}
        </Link>
        <Link to="/">
          <h1 className="header-title">{siteTitle}</h1>
        </Link>
      </div>
      <nav>
        <hr className="header-hr" />
        <Link to="/news">Neuigkeiten</Link> |{" "}
        <Link to="/training">Training</Link> |{" "}
        <Link to="/dokumente">Dokumente</Link> |{" "}
        <Link to="/kontakt">Kontakt</Link>
      </nav>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
