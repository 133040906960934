import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import Archive from "./archive";
import Image from "./image";
import Footer from "./footer";
import "./layout.scss";

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        {location && location.pathname === "/" && (
          <Image
            className="index-image"
            filename="header.jpg"
            alt="Blick in einen Tischfußballkasten"
          />
        )}
        <div className="main-div-wrapper">
          {/* grid with 2 columns */}
          <main>
            <>{children}</>
            <Archive />
          </main>
          <Footer />
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
