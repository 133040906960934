import { Link } from "gatsby";
import React from "react";

const Footer = () => (
  <footer>
    <hr />
    <div className="footer-div">
      <div className="footer-div-left">
        © {new Date().getFullYear()} Max Rumford. Gebaut mit
        {` `}
        <a href="https://www.gatsbyjs.org" rel="noopener noreferrer">
          Gatsby
        </a>
        .
      </div>
      <div className="footer-div-right">
        {" "}
        <Link to="/impressum">Impressum</Link> |{" "}
        <Link to="/datenschutz">Datenschutz</Link>
      </div>
    </div>
  </footer>
);

export default Footer;
